import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { range } from "lodash";
import {
  sessionTimeAndCoughAnalysis,
  SessionTimeAndCoughAnalysisProps,
} from "@hyfe/cough-plots";

import DayOfYear from "dayjs/plugin/dayOfYear.js";
import dayjs from "dayjs";
dayjs.extend(DayOfYear);

interface CoughSummaryProps {
  coughs: SessionTimeAndCoughAnalysisProps["coughs"];
  sessions: SessionTimeAndCoughAnalysisProps["sessions"];
}

const lastWeekDays = range(0, 7).map((d) =>
  dayjs().subtract(d, "day").dayOfYear()
);
const historicDays = range(7, 5 * 7).map((d) =>
  dayjs().subtract(d, "day").dayOfYear()
);

export const CoughSummary = ({ coughs, sessions }: CoughSummaryProps) => {
  const [lastWeek, setLastWeek] = useState<{
    sessionTimePerDay: number;
    coughsPerHour: number;
    cumulativeSessionTime: number;
  }>({
    sessionTimePerDay: 0,
    coughsPerHour: 0,
    cumulativeSessionTime: 0,
  });
  const [historic, setHistoric] = useState<{
    sessionTimePerDay: number;
    coughsPerHour: number;
    cumulativeSessionTime: number;
  }>({
    sessionTimePerDay: 0,
    coughsPerHour: 0,
    cumulativeSessionTime: 0,
  });

  // console.log({ lastWeek, historic });
  useEffect(() => {
    const analysis = sessionTimeAndCoughAnalysis({
      coughs,
      sessions,
      unit: "dayOfYear",
      unitNumbers: [...lastWeekDays, ...historicDays],
    });

    // console.log(analysis);
    const numValidDaysLastWeek = lastWeekDays.filter(
      (d) => analysis[d].coughsPerHour !== null
    ).length;
    const numValidDaysHistoric = historicDays.filter(
      (d) => analysis[d].coughsPerHour !== null
    ).length;

    setLastWeek({
      coughsPerHour:
        numValidDaysLastWeek === 0
          ? -1
          : lastWeekDays
              .map((d) => analysis[d].coughsPerHour || 0)
              .reduce((acc, cph) => cph + acc, 0) / numValidDaysLastWeek,
      sessionTimePerDay:
        lastWeekDays
          .map((d) => analysis[d].sessionTimeHours)
          .reduce((acc, sesTime) => sesTime + acc, 0) / lastWeekDays.length,
      cumulativeSessionTime: lastWeekDays
        .map((d) => analysis[d].sessionTimeHours)
        .reduce((acc, sesTime) => sesTime + acc, 0),
    });
    setHistoric({
      coughsPerHour:
        numValidDaysHistoric === 0
          ? -1
          : historicDays
              .map((d) => analysis[d].coughsPerHour || 0)
              .reduce((acc, cph) => cph + acc, 0) / numValidDaysHistoric,
      sessionTimePerDay:
        historicDays
          .map((d) => analysis[d].sessionTimeHours)
          .reduce((acc, sesTime) => sesTime + acc, 0) / historicDays.length,
      cumulativeSessionTime: historicDays
        .map((d) => analysis[d].sessionTimeHours)
        .reduce((acc, sesTime) => sesTime + acc, 0),
    });
  }, [coughs, sessions]);

  let cphPercentDiff = "";
  let sesTimePercentDiff = "";
  if (
    lastWeek.coughsPerHour > 0 &&
    lastWeek.cumulativeSessionTime > 2 &&
    historic.coughsPerHour > 0 &&
    historic.cumulativeSessionTime > 2
  ) {
    let cphDiff =
      (lastWeek.coughsPerHour - historic.coughsPerHour) /
      historic.coughsPerHour;
    cphPercentDiff =
      (cphDiff > 0 ? "+" : "-") + Math.abs(cphDiff * 100).toFixed(1);

    let sesTimeDiff =
      (lastWeek.sessionTimePerDay - historic.sessionTimePerDay) /
      historic.sessionTimePerDay;
    sesTimePercentDiff =
      (sesTimeDiff > 0 ? "+" : "-") + Math.abs(sesTimeDiff * 100).toFixed(1);
  }

  return (
    <Container>
      {lastWeek.coughsPerHour > 0 &&
        lastWeek.cumulativeSessionTime > 2 &&
        historic.coughsPerHour > 0 &&
        historic.cumulativeSessionTime > 2 && (
          <>
            <HeaderText>
              Your result over the last 7 days
              <br />
              (vs preceding 4 weeks)
            </HeaderText>
            <ResultsGroup>
              <ResultsText>{`${lastWeek.sessionTimePerDay.toFixed(
                1
              )} (${sesTimePercentDiff}%)`}</ResultsText>
              <ResultsUnits>Hours tracked / day</ResultsUnits>
            </ResultsGroup>
            <ResultsGroup>
              <ResultsText>{`${lastWeek.coughsPerHour.toFixed(
                1
              )} (${cphPercentDiff}%)`}</ResultsText>
              <ResultsUnits>Coughs / hour</ResultsUnits>
            </ResultsGroup>
          </>
        )}
    </Container>
  );
};

export default CoughSummary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HeaderText = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const ResultsGroup = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
`;
const ResultsText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
const ResultsUnits = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #d7dbe0;
`;
