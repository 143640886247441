import React, { useContext, useEffect, useRef, useState } from "react";

import {
  fetchSessions,
  FirebasePeak,
  getCoughsFetcher,
  HourlyCoughs_Plot,
  Period_CpH_Plot,
} from "@hyfe/cough-plots";

import { jsPDF } from "jspdf";

import styled from "styled-components";
import dayjs from "dayjs";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import DayOfYear from "dayjs/plugin/dayOfYear";

dayjs.extend(WeekOfYear);
dayjs.extend(DayOfYear);
import { useDataFetcher } from "./useDataFetcher";
import { InitFirestore } from "../../firebase/firebase";

import { AuthContext } from "../../context/auth";
import { findLastIndex } from "../../misc";
import { CoughSummary } from "../report/coughSummary";

const MODE: "light" | "dark" = "dark";

const formatMonthlyPlotTitle = (labels: string[], coughs: number[]) => {
  const coughsCurMonth = coughs[coughs.length - 1];
  const comparedMonth = findLastIndex(coughs.slice(0, -1), (c) => c > 0);

  if (coughsCurMonth && comparedMonth != -1) {
    let coughsComparedMonth = coughs[comparedMonth];
    const diff = (coughsComparedMonth - coughsCurMonth) / coughsComparedMonth;
    const comparedMonthName =
      comparedMonth == coughs.length - 2
        ? "last month"
        : `${labels[comparedMonth]}`;
    return [
      `This month you coughed ${Math.abs(diff * 100).toFixed(0)}% ${
        diff > 0 ? "less" : "more"
      } than`,
      comparedMonthName,
    ];
  } else {
    return [
      `Here's an overview of your cough for the`,
      `last ${coughs.length} months`,
    ];
  }
};
const formatWeeklyPlotTitle = (labels: string[], coughs: number[]) => {
  const coughsCurWeek = coughs[coughs.length - 1];
  const comparedWeek = findLastIndex(coughs.slice(0, -1), (c) => c > 0);
  if (coughsCurWeek && comparedWeek != -1) {
    let coughsComparedWeek = coughs[comparedWeek];
    const diff = (coughsComparedWeek - coughsCurWeek) / coughsComparedWeek;
    const comparedWeekName =
      comparedWeek == coughs.length - 2
        ? "last week"
        : `${coughs.length - comparedWeek - 1} weeks ago`;
    return [
      `This week you coughed ${Math.abs(diff * 100).toFixed(0)}% ${
        diff > 0 ? "less" : "more"
      } than`,
      comparedWeekName,
    ];
  } else {
    return [`Here's an overview of your cough for the`, `last ${4} weeks`];
  }
};
const formatDailyPlotTitle = (labels: string[], coughs: number[]) => {
  return ["Here's an overview of your cough for the", "last 7 days"];
};
const formatHourlyPlotTitle = (
  labels: (string | string[])[],
  coughs: number[]
) => {
  const maxCoughs = Math.max(...coughs);
  const maxCoughsIdx = coughs.findIndex((c) => c === maxCoughs);
  if (maxCoughs > 0) {
    return [
      `You cough the most between ${labels[maxCoughsIdx]} and`,
      `${labels[(maxCoughsIdx + 1) % labels.length]}`,
    ];
  } else {
    return [`Here's an overview of your cough during`, `the day`];
  }
};

const Report = () => {
  const { user, isLoading } = useContext(AuthContext);

  const monthPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const weekPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const dayPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const hourlyPlotCanvasRef = useRef<HTMLCanvasElement>(null);

  const offScreenMonthPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const offScreenWeekPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const offScreenDayPlotCanvasRef = useRef<HTMLCanvasElement>(null);
  const offScreenHourlyPlotCanvasRef = useRef<HTMLCanvasElement>(null);

  const [monthPlot, setMonthPlot] =
    useState<ReturnType<typeof Period_CpH_Plot>>();
  const [weekPlot, setWeekPlot] =
    useState<ReturnType<typeof Period_CpH_Plot>>();
  const [dayPlot, setDayPlot] = useState<ReturnType<typeof Period_CpH_Plot>>();
  const [hourlyPlot, setHourlyPlot] =
    useState<ReturnType<typeof HourlyCoughs_Plot>>();

  const [offScreenMonthPlot, setOffScreenMonthPlot] =
    useState<ReturnType<typeof Period_CpH_Plot>>();
  const [offScreenWeekPlot, setOffscreenWeekPlot] =
    useState<ReturnType<typeof Period_CpH_Plot>>();
  const [offScreenDayPlot, setOffScreenDayPlot] =
    useState<ReturnType<typeof Period_CpH_Plot>>();
  const [offScreenHourlyPlot, setOffScreenHourlyPlot] =
    useState<ReturnType<typeof HourlyCoughs_Plot>>();

  const [sessions, setSessions] = useState<any[]>([]);

  const reportStartDate = dayjs(); //now;
  const uid = user?.uid || "";
  // const uid = "TRt6hwm8odbxqYd6jexrgejuF2N2";
  // const uid = "KCzNQi6qZNWQGw1QeTo84haftgd2";
  // const uid = "5DzZ2LA8Bvhbkr0ctRsjGz1Xr9F3";
  // const uid = "pMPMVbnof9ViECOimSbTZXwj9jz2";
  // const uid = "Mboi94Xygpe4ZvmzNzHrQ6MgnH83";
  // const uid = "IdMENq8F0xOlMwVNXy4lFVdYxkB3";
  const { data: coughs, finished } = useDataFetcher({
    q: getCoughsFetcher(
      InitFirestore,
      uid,
      reportStartDate.subtract(3, "month").startOf("month").unix() * 1000,
      reportStartDate.unix() * 1000
    ),
    agg: (prevData: FirebasePeak[], newData: FirebasePeak[]) => {
      return [...prevData, ...newData];
    },
    init: [],
  });

  useEffect(() => {
    fetchSessions(
      InitFirestore,
      uid,
      reportStartDate.subtract(3, "month").startOf("month").unix() * 1000,
      reportStartDate.unix() * 1000
    )
      .then((s) => {
        setSessions(s);
      })
      .catch((err) => console.error(err));

    if (
      !monthPlotCanvasRef.current ||
      !weekPlotCanvasRef.current ||
      !dayPlotCanvasRef.current ||
      !hourlyPlotCanvasRef.current ||
      !offScreenHourlyPlotCanvasRef.current ||
      !offScreenMonthPlotCanvasRef.current ||
      !offScreenWeekPlotCanvasRef.current ||
      !offScreenDayPlotCanvasRef.current
    )
      return;

    // Initialize plots
    setMonthPlot(
      Period_CpH_Plot(monthPlotCanvasRef.current, {
        mode: MODE,
        unit: "month",
        columns: [
          reportStartDate.month() - 2,
          reportStartDate.month() - 1,
          reportStartDate.month(),
        ],
        highlightColumIdx: 2,

        formatColumn: (column) => reportStartDate.month(column).format(`MMM`),
        formatTitle: formatMonthlyPlotTitle,
        minSessionHours: 15 / 60,
      })
    );
    setHourlyPlot(
      HourlyCoughs_Plot(hourlyPlotCanvasRef.current, {
        mode: MODE,
        formatTitle: formatHourlyPlotTitle,
      })
    );
    setWeekPlot(
      Period_CpH_Plot(weekPlotCanvasRef.current, {
        mode: MODE,
        unit: "week",
        columns: [
          reportStartDate.week() - 3,
          reportStartDate.week() - 2,
          reportStartDate.week() - 1,
          reportStartDate.week(),
        ],
        highlightColumIdx: 3,
        formatColumn: (column) => reportStartDate.week(column).format(`MMM DD`),
        formatTitle: formatWeeklyPlotTitle,
      })
    );
    setDayPlot(
      Period_CpH_Plot(dayPlotCanvasRef.current, {
        mode: MODE,
        unit: "dayOfYear",
        columns: [...Array(7).keys()].map((k) =>
          reportStartDate.subtract(6 - k, "day").dayOfYear()
        ),
        formatColumn: (column) =>
          reportStartDate.dayOfYear(column).format(`dd`),
        formatTitle: formatDailyPlotTitle,
        highlightColumIdx: 6,
        averageLine: true,
      })
    );

    // Offscreen plots for the report
    setOffScreenMonthPlot(
      Period_CpH_Plot(offScreenMonthPlotCanvasRef.current, {
        mode: "light",
        unit: "month",
        columns: [
          reportStartDate.month() - 2,
          reportStartDate.month() - 1,
          reportStartDate.month(),
        ],
        highlightColumIdx: 2,
        formatColumn: (column) => reportStartDate.month(column).format(`MMM`),
        formatTitle: formatMonthlyPlotTitle,
        minSessionHours: 15 / 60,
      })
    );
    setOffscreenWeekPlot(
      Period_CpH_Plot(offScreenWeekPlotCanvasRef.current, {
        mode: "light",
        unit: "week",
        columns: [
          reportStartDate.week() - 3,
          reportStartDate.week() - 2,
          reportStartDate.week() - 1,
          reportStartDate.week(),
        ],
        highlightColumIdx: 3,
        formatColumn: (column) => reportStartDate.week(column).format(`MMM DD`),
        formatTitle: formatWeeklyPlotTitle,
      })
    );
    setOffScreenDayPlot(
      Period_CpH_Plot(offScreenDayPlotCanvasRef.current, {
        mode: "light",
        unit: "dayOfYear",
        columns: [...Array(7).keys()].map((k) =>
          reportStartDate.subtract(6 - k, "day").dayOfYear()
        ),
        formatColumn: (column) =>
          reportStartDate.dayOfYear(column).format(`dd`),
        formatTitle: formatDailyPlotTitle,
        highlightColumIdx: 6,
        averageLine: true,
      })
    );
    setOffScreenHourlyPlot(
      HourlyCoughs_Plot(offScreenHourlyPlotCanvasRef.current, {
        mode: "light",
        formatTitle: formatHourlyPlotTitle,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (monthPlot) monthPlot.update({ sessions, coughs });
    if (weekPlot) weekPlot.update({ sessions, coughs });
    if (dayPlot) dayPlot.update({ sessions, coughs });
    if (hourlyPlot) hourlyPlot.update({ sessions, coughs });
    if (offScreenMonthPlot) offScreenMonthPlot.update({ sessions, coughs });
    if (offScreenWeekPlot) offScreenWeekPlot.update({ sessions, coughs });
    if (offScreenDayPlot) offScreenDayPlot.update({ sessions, coughs });
    if (offScreenHourlyPlot) offScreenHourlyPlot.update({ sessions, coughs });
  }, [coughs, sessions]);

  const downloadPdf = async () => {
    if (
      !offScreenMonthPlot ||
      !offScreenWeekPlot ||
      !offScreenDayPlot ||
      !offScreenHourlyPlot
    )
      return;
    const plots = [
      offScreenMonthPlot,
      offScreenWeekPlot,
      offScreenDayPlot,
      offScreenHourlyPlot,
    ];

    var doc = new jsPDF("p", "mm", "a4");

    // Title
    doc.setFontSize(16);

    doc.setTextColor("#213458");
    doc.text(
      `Hi ${
        user?.displayName || "CoughTracker User"
      }, this is your cough report.`,
      20,
      20
    );

    const xPadding = 20;
    const yPadding = 20;
    const xSize = (210 - xPadding * 3) / 2;
    const ySize = xSize;

    let x = 0;
    let y = 30;

    plots.forEach((p, idx) => {
      if (idx % 2 == 0) {
        x = xPadding;
      }

      doc.addImage(
        p.chart.toBase64Image("image/jpeg", 1),
        "jpeg",
        x,
        y,
        xSize,
        ySize
      );

      if (idx % 2 == 0) {
        x += xSize + xPadding;
      }
      if (idx % 2 == 1) {
        y += ySize + yPadding;
      }
    });

    doc.save(`cough_report_${dayjs().format("MM_YYYY")}.pdf`);
  };

  return (
    <Container>
      <OffScreen>
        <OffScreenPlotsContainer>
          <canvas ref={offScreenMonthPlotCanvasRef}></canvas>
          <canvas ref={offScreenWeekPlotCanvasRef}></canvas>
          <canvas ref={offScreenDayPlotCanvasRef}></canvas>
          <canvas ref={offScreenHourlyPlotCanvasRef}></canvas>
        </OffScreenPlotsContainer>
      </OffScreen>
      <ReportContainer>
        <ReportHeader>
          <div className="greet">{`Hi ${
            user?.displayName || "CoughTracker User"
          }`}</div>
          <div className="subtitle">{`This is your cough report`}</div>
          <DownloadButton disabled={!finished} onClick={downloadPdf}>
            Download PDF
          </DownloadButton>
          <CoughSummary coughs={coughs} sessions={sessions} />
        </ReportHeader>
        <PlotsContainer>
          <PlotWrapper>
            <canvas ref={monthPlotCanvasRef}></canvas>
          </PlotWrapper>
          <PlotWrapper>
            <canvas ref={weekPlotCanvasRef}></canvas>
          </PlotWrapper>
          <PlotWrapper>
            <canvas ref={dayPlotCanvasRef}></canvas>
          </PlotWrapper>
          <PlotWrapper>
            <canvas ref={hourlyPlotCanvasRef}></canvas>
          </PlotWrapper>
        </PlotsContainer>
      </ReportContainer>
    </Container>
  );
};

export default Report;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const OffScreenPlotsContainer = styled.div`
  width: 800px;
`;
const OffScreen = styled.div`
  position: absolute;
  left: 100vw;
`;

const ReportContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  background-color: ${
    // @ts-expect-error mode changed manually
    MODE === "light" ? "#FFFFFF" : "#213458"
  };
  padding-top: 40px;
  padding-bottom: 60px;
`;
const ReportHeader = styled.div`
  .title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }
  .subtitle {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #d7dbe0;
  }

  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 24px;
  font-family: "Poppins";
  color: ${
    // @ts-expect-error mode changed manually
    MODE === "light" ? "#2A426F" : "#FFFFFF"
  };
  text-align: left;
`;

const PlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;

  gap: 40px;
`;

const PlotWrapper = styled.div`
  /* flex-grow: 1; */
  min-width: 360px;
  max-width: 600px;
  /* max-width: 50%; */
  /* flex-basis: 1; */
`;

const DownloadButton = styled.button`
  background-color: #3891a6;
  color: #ffffff;

  padding: 12px 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  text-transform: uppercase;

  border-radius: 24px;
  border: none;

  margin: 18px 0px;
`;
