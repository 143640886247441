import React, { useContext, useEffect } from "react";
import { navigate, PageProps } from "gatsby";

import { AuthContext } from "../context/auth";

import Loading from "./loading";
import { SignInWithJWTStates, useSignInWithJWT } from "../firebase/firebase";
import useLocalStorage from "use-local-storage";

interface PrivateRouteProps extends PageProps {
  component: typeof React.Component;
}

const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: PrivateRouteProps) => {
  let params = new URLSearchParams(location.search);

  const token = params.get("token");
  const { status } = useSignInWithJWT(token as string);
  const { user, isLoading } = useContext(AuthContext);
  const [isTokenLogIn, setIsTokenLogIn] = useLocalStorage<boolean | null>(
    "isTokenLogIn",
    null
  );

  useEffect(() => {
    if (status === SignInWithJWTStates.SUCESS) {
      navigate(location.pathname);
    } else if (
      [
        SignInWithJWTStates.ERROR,
        SignInWithJWTStates.FAILED_GET_CUSTOM_TOKEN,
        SignInWithJWTStates.FAILED_LOGIN_CUSTOM_TOKEN,
      ].includes(status)
    ) {
      navigate("/tokenLoggedOut");
    }
  }, [status]);

  // If token provided wait for the login process inside
  // the useEffect to redirect to the relevant place
  if (token) {
    return <Loading />;
  }

  // Auth is loading
  if (isLoading) {
    return <Loading />;
  }

  // Auth failed
  if (!user && location.pathname !== `/login`) {
    if (isTokenLogIn) {
      setIsTokenLogIn(false);
      navigate("/tokenLoggedOut");
    } else {
      navigate("/login");
    }

    return <Loading />;
  }

  return <Component {...rest} location={location} />;
};

export default PrivateRoute;
