import { useEffect, useRef, useState } from "react";

export type DataFetcher<T> = () => Promise<{
  data: T | null;
  finished: boolean;
}>;
export interface useDataFetcherProps<T, TT> {
  q: DataFetcher<T>;
  agg: (a: TT, b: T) => TT;
  init: TT;
}

export const useDataFetcher = <T, TT>({
  q,
  agg,
  init,
}: useDataFetcherProps<T, TT>) => {
  const [data, setData] = useState(init);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false);

  const finishedRef = useRef(finished);
  finishedRef.current = finished;

  const fetchLoop = async () => {
    while (!finishedRef.current) {
      const newData = await q();
      if (newData.data != null) {
        setData((d) => agg(d, newData.data as T));
        if (newData.finished) {
          setFinished(true);
        }
      } else {
        setFinished(true);
        break;
      }
    }
  };

  useEffect(() => {
    setData(init);
    setFinished(false);
    fetchLoop();
    return () => setFinished(true);
  }, []);

  return { data, finished };
};
