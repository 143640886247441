import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout/layout";
import PrivateRoute from "../components/privateRoute";

// import HTMLReportPage from "../components/page_components/html_report";
import Report from "../components/page_components/report";
import NotFound from "./404";

const Dashboard = () => (
  <Layout>
    <Router basepath="/dashboard">
      <PrivateRoute component={Report} path="/report" />
      <NotFound default />
    </Router>
  </Layout>
);
export default Dashboard;
